import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Callback() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    const userId = urlParams.get("user_id");
    const username = urlParams.get("username");
    const permissions = urlParams.get("permissions");  // Obtener los permisos desde la URL

    if (token && userId) {
      
      sessionStorage.setItem("jwt", token);
      sessionStorage.setItem("user_id", userId);
      sessionStorage.setItem("permissions", permissions);  // Guardar los permisos en sessionStorage
      sessionStorage.setItem("username",username);

      localStorage.setItem("jwt", token);
      localStorage.setItem("user_id", userId);
      localStorage.setItem("permissions", permissions);  // Guardar los permisos en localStorage
      localStorage.setItem("username",username);

      const redirectPath = sessionStorage.getItem("redirectPath") || "/";
      sessionStorage.removeItem("redirectPath");

      
      navigate(redirectPath, { replace: true });
    } else {
      
      navigate("/login");
    }
  }, [navigate, location]);

  return null;
}
