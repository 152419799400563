import { getAuthToken } from './storage';

export const classifyMessage = async (messageId, classification, userId, sessionId, messages, setMessages) => {
    try {
        // Log para asegurar que los parámetros se están recibiendo correctamente
        
        const authToken = getAuthToken();  // Asegúrate de que esta función esté definida para obtener el token de autenticación.

        // Buscar el mensaje correspondiente al messageId
        const message = messages.find(m => m.id === messageId);
        if (!message) {
            console.error('Message not found for id:', messageId);
            return;
        }

        // Log para verificar el contenido del mensaje antes de la solicitud
        

        // Preparar el cuerpo de la solicitud
        const requestBody = {
            user_id: userId,
            session_id: sessionId,
            question: message.text,
            timestamp: new Date().toISOString(),
            classification: classification
        };

        // Log para ver el cuerpo de la solicitud antes de enviarlo
        

        // Realizar la solicitud fetch
        const response = await fetch('https://chata.superlab.ai/chatbot/classify_message', { 
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`  // Asegúrate de incluir el token en los headers si es necesario
            },
            body: JSON.stringify(requestBody),
        });

        // Log para ver la respuesta antes de chequear si es ok
        

        // Manejo de errores en la respuesta
        if (!response.ok) {
            console.error('Failed to classify message:', response.status, response.statusText);
            throw new Error('Failed to classify message');
        }

        

        // Actualizar el estado local
        setMessages(prevMessages =>
            prevMessages.map(msg =>
                msg.id === messageId
                    ? { ...msg, classification: classification }
                    : msg
            )
        );

    } catch (error) {
        console.error('Error classifying message:', error);
    }
};
