import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Avatar, Button } from "@nextui-org/react";
import { LogoSuper } from "./LogoSuper.jsx";
import { ChevronDown, LogOut, MessageCircle } from "lucide-react";

const APP_ID = "chata";

export const MenuSuperior = () => {
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({
    username: '',
    session_id: '',
    clients: [],
    permissions: {}
  });

  useEffect(() => {
    const fetchUserData = () => {
      try {
        const storedData = JSON.parse(localStorage.getItem("permissions") || "{}");
        const session_id = localStorage.getItem("session_id");
        const username = localStorage.getItem("username");
        
        setUserInfo({
          username: username || '',
          session_id: session_id || '',
          clients: storedData.clients || [],
          permissions: storedData
        });
      } catch (error) {
        console.error("Error retrieving user data:", error);
      }
    };

    fetchUserData();
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `http://sso.superlab.ai/login?next=${window.location.origin}/callback&app_id=${APP_ID}`;
  };

  const renderClientLinks = () => {
    const availableClients = userInfo.clients.includes("all") 
      ? ["banco_macro", "banco_ciudad"] 
      : userInfo.clients;
  
    return availableClients.map(client => {
      let clientName = client.replace('banco_', '').charAt(0).toUpperCase() + client.replace('banco_', '').slice(1);
      let clientPath = client.replace('banco_', '');
  
      if (client === "banco_ciudad") {
        clientName = "Banco Ciudad";
        clientPath = "bancociudad";
      }
  
      return (
        <DropdownItem key={client} onPress={() => window.location.href = `/${clientPath}`} endContent={<MessageCircle size={16} />}>
          {clientName}
        </DropdownItem>
      );
    });
  };

  return (
    <Navbar isBordered className="bg-naranja">
      <NavbarBrand>
        <LogoSuper />
        <div className="flex flex-col items-start ml-2">
          <p className="text-xl font-bold" style={{ color: '#db043eff' }}>
            Chata AI 
            <span className="text-sm font-normal ml-1">v3.2</span>
          </p>
          <p className="text-xs font-semibold" style={{ color: '#ffff', marginTop: '-5px' }}>
            (BETA)
          </p>
        </div>
      </NavbarBrand>

      <NavbarContent justify="center">
        <NavbarItem>
          <Link 
            href="/"
            color={location.pathname === "/" ? "secondary" : "foreground"}
            className={location.pathname === "/" ? 'text-red-500' : ''}
          >
            Home
          </Link>
        </NavbarItem>
      </NavbarContent>

      <NavbarContent justify="end">
        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <Button 
              variant="light" 
              className="text-sm"
              endContent={<ChevronDown size={16} />}
            >
              <Avatar
                isBordered
                color="secondary"
                size="sm"
                name={`User ${userInfo.username}`}
              />
              <span className="hidden md:inline">User {userInfo.username}</span>
            </Button>
          </DropdownTrigger>
          <DropdownMenu aria-label="User menu actions">
            <DropdownItem key="profile" className="h-14 gap-2">
              <p className="font-bold">Signed in as</p>
              <p className="font-bold">User {userInfo.username}</p>
            </DropdownItem>
            <DropdownItem key="chata" className="font-bold" disabled>
              Chata
            </DropdownItem>
            {renderClientLinks()}
            <DropdownItem key="logout" color="danger" onPress={handleLogout} endContent={<LogOut size={16} />}>
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </NavbarContent>
    </Navbar>
  );
};